var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sidebar", {
    ref: "sidebar",
    staticClass: "d-flex flex-column",
    attrs: { title: _vm.sidebar.title, links: _vm.sidebar.children },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }