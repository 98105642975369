var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.links.length
    ? _c("div", [
        _c("div", { staticClass: "sidebar" }, [
          _c(
            "h2",
            {
              staticClass:
                "sidebar__title text--title block block-xs--xs-bottom",
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _c(
            "div",
            { staticClass: "sidebar__list" },
            _vm._l(_vm.links, function (item, key) {
              return _c(
                "div",
                { key: key, staticClass: "sidebar__list-item" },
                [
                  _c("span", { staticClass: "sidebar__item-title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "sidebar__sub-items" },
                    _vm._l(item.children, function (sublink) {
                      return _c(
                        "div",
                        { staticClass: "sidebar__sub-item" },
                        [
                          sublink.title === undefined &&
                          sublink.children === undefined
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "sidebar__sub-item-link",
                                  attrs: {
                                    to: _vm.getLink(sublink.to),
                                    activeClass:
                                      "sidebar__sub-item-link--active",
                                  },
                                },
                                [_vm._v(_vm._s(sublink.display))]
                              )
                            : _c(
                                "div",
                                { staticClass: "sidebar__sub-sub-items" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "sidebar__item-title" },
                                    [_vm._v(_vm._s(sublink.title))]
                                  ),
                                  _vm._l(
                                    sublink.children,
                                    function (subsublink) {
                                      return _c(
                                        "div",
                                        {
                                          key: subsublink.to.path,
                                          staticClass: "sidebar__sub-item",
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "sidebar__sub-sub-item-link",
                                              attrs: {
                                                to: _vm.getLink(subsublink.to),
                                                activeClass:
                                                  "sidebar__sub-sub-item-link--active",
                                              },
                                            },
                                            [_vm._v(_vm._s(subsublink.display))]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("br"),
                ]
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }