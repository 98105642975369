<template>
  <sidebar
    ref="sidebar"
    :title="sidebar.title"
    :links="sidebar.children"
    class="d-flex flex-column"
  />
</template>

<script>
import AccessMixin from "mixins/access_helper";
import Sidebar from "components/sidebar";
import { mapGetters } from "vuex";
import { authHttp } from "@/http";

export default {
    name: "store_admin_sidebar",
    mixins: [AccessMixin],
    components: {
        Sidebar,
    },
    data() {
        return {
            sidebarUpsellGroup: {
                title: "Upsell & Add On",
                children: [
                    {
                        to: { name: "upsell-menu-item" },
                        display: "Upsell Items",
                    },
                    {
                        to: { name: "addon-menu-item" },
                        display: "Add On Items",
                    },
                    {
                        to: { name: "upsell-group" },
                        display: "Groups",
                    },
                ],
            },
            sidebarAlertsGroup: {
                title: "ALERTS",
            },
            showLanguages: false,
        };
    },
    computed: {
        ...mapGetters(["store", "dev", "posProducts", "user", "superuser"]),
        bulkEditEnabled() {
            return true;
        },
        sidebarMenusGroup() {
            var baseChildren = [
                {
                    to: { name: "store-products" },
                    display: "Products",
                },
            ];

            if (this.userHasViewPermission("IMAGES")) {
                baseChildren = baseChildren.concat({
                    to: { name: "store-images" },
                    display: "Images",
                });
            }

            if (this.bulkEditEnabled) {
                baseChildren = baseChildren.concat({
                    to: { name: "store-product-bulk-edit" },
                    display: "Bulk Edit",
                });
            }
            if (this.showLanguages)
                baseChildren = baseChildren.concat({
                    to: { name: "store-languages" },
                    display: "Translations",
                });

            return {
                title: "MENUS",
                children: baseChildren,
            };
        },
        sidebarSettingsGroup() {
            let children = [
                {
                    to: {
                        name: "store-settings",
                        params: { env: "stage" },
                    },
                    display: "Store Settings",
                },
            ];
            if (this.superuser) {
                children.push({
                    to: { name: "store-notes" },
                    display: "Store Notes",
                });
                children.push({
                    to: { name: "pos-notes" },
                    display: "POS Notes",
                });
            }

            if (this.dev) {
                children.push({
                    to: { name: "store-upsell-settings" },
                    display: "Upsell",
                });
            }

            return {
                title: "SETTINGS",
                children,
            };
        },
        sidebarDevGroup() {
            let children = [
                {
                    to: { name: "store-settings" },
                    display: `POS: ${this.store.storePOSType}`,
                },
                {
                    to: { name: "store-settings" },
                    display: `POS-SYNCED?: ${
                        this.posProducts !== undefined && this.posProducts.length != 0
                    }`,
                },
            ];

            return {
                title: "DEV",
                children,
            };
        },
        sidebar() {
            let children = [];
            children = children.concat(this.sidebarMenusGroup);

            if (this.userHasViewPermission("UPSELL")) {
                children = children.concat(this.sidebarUpsellGroup);
            }
            children = children.concat(this.sidebarSettingsGroup);

            if (this.dev) children = children.concat(this.sidebarDevGroup);
            return {
                title: "Store Admin",
                children,
            };
        },
    },
    created() {
        this.checkLanguageSupport();
    },
    methods: {
        checkLanguageSupport() {
            this.showLanguages = false;
            authHttp
                .get(`/api/languages/waypoint/${this.$route.params.store}`)
                .then((res) => {
                    console.log(res);
                    this.showLanguages =
            res.data.languages.filter((el) => el !== "en").length > 0;
                });
        },
    },
    watch: {
        "$route.params.store"() {
            this.checkLanguageSupport();
        },
    },
};
</script>

<style scoped>
</style>
