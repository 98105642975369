<template>
  <div v-if="links.length">
    <div class="sidebar">
      <h2 class="sidebar__title text--title block block-xs--xs-bottom">{{ title }}</h2>

      <div class="sidebar__list">
        <div v-for="(item, key) in links" :key="key" class="sidebar__list-item">
          <span class="sidebar__item-title">{{ item.title }}</span>

          <div class="sidebar__sub-items">
            <div v-for="sublink in item.children" class="sidebar__sub-item">
              <router-link
                v-if="sublink.title === undefined && sublink.children === undefined"
                :to="getLink(sublink.to)"
                class="sidebar__sub-item-link"
                activeClass="sidebar__sub-item-link--active"
              >{{ sublink.display }}</router-link>

              <div v-else class="sidebar__sub-sub-items">
                <span class="sidebar__item-title">{{ sublink.title }}</span>

                <div
                  v-for="subsublink in sublink.children"
                  :key="subsublink.to.path"
                  class="sidebar__sub-item"
                >
                  <router-link
                    :to="getLink(subsublink.to)"
                    class="sidebar__sub-sub-item-link"
                    activeClass="sidebar__sub-sub-item-link--active"
                  >{{ subsublink.display }}</router-link>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Sidebar",
    props: {
        links: Array,
        title: String
    },
    methods: {
        getLink(link) {
            if (typeof link === "string") {
                return { path: link, params: this.$route.params };
            }

            return {
                ...link,
                params: {...link.params, ...this.$route.params}
            };
        }
    }
};
</script>

<style lang="scss">
@import "../scss/variables";
@import "../scss/mixins";

.sidebar {
  height:100%;
  background-color: $grey_light_2;
  padding: spacing(sm) spacing(xl);
  font-size: 14px;
  &__title {
    margin-bottom:6px;
  }
  &__item-title {
    color: $brand_color;
    cursor: default;
  }

  &__sub-items {
    padding-left: 10px;
    line-height: 1.6em;
    transition: line-height 400ms cubic-bezier(0.65, 0.05, 0.36, 1);
    overflow: hidden;
  }

  &__sub-sub-items {
    line-height: 1.6em;
    transition: line-height 400ms cubic-bezier(0.65, 0.05, 0.36, 1);
    overflow: hidden;
  }

  &__sub-item-link {
    @include roboto-medium;
    color: $grey_mid_1 !important;

    &--active {
      color: $copy_color !important;
    }
  }

  &__sub-sub-item-link {
    @include roboto-medium;
    color: $grey_mid_1 !important;
    padding-left: 20px;

    &--active {
      color: $copy_color !important;
    }
  }
}
</style>
